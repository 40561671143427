import { render, staticRenderFns } from "./detail_local_delivery.vue?vue&type=template&id=71224df6&scoped=true&"
import script from "./detail_local_delivery.vue?vue&type=script&lang=js&"
export * from "./detail_local_delivery.vue?vue&type=script&lang=js&"
import style0 from "./detail_local_delivery.vue?vue&type=style&index=0&id=71224df6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71224df6",
  null
  
)

export default component.exports